
<script setup>
    import { ref, watch, onMounted } from "vue";
    import MSlider from "./components.Slider.vue";
    import { alert } from 'o365-vue-services';
    import { hapticsImpact } from "./utils.ts";

    const props = defineProps({
        filter: { type: Object, required: true },
    });

    const loading = ref(false);

    const min = ref(0);
    const max = ref(0);

    const start = ref(0);
    const stop = ref(0);

    const range_min = ref(0);
    const range_max = ref(100);

    async function update() {
        loading.value = true;

        const options = {};

        options.viewName = props.filter.dataObject.viewName;
        options.whereClause = props.filter.dataObject.recordSource.combinedWhereClause;
        options.filterString = props.filter.getFilterString(props.filter.field);

        options.maxRecords = 50;
        options.fields = [
            {name: props.filter.field.from, alias: "min1", aggregate: "MIN"},
            {name: props.filter.field.to  , alias: "min2", aggregate: "MIN"},
            {name: props.filter.field.from, alias: "max1", aggregate: "MAX"},
            {name: props.filter.field.to  , alias: "max2", aggregate: "MAX"},
        ];

        console.log("fields", options.fields);

        let rows = await props.filter.dataObject.dataHandler.retrieve(options);

        let min = Math.min(rows[0].min1 ??  Infinity, rows[0].min2 ??  Infinity);
        let max = Math.max(rows[0].max1 ?? -Infinity, rows[0].max2 ?? -Infinity);

        // there's no data to get range from
        if (Math.abs(min) === Infinity || Math.abs(max) === Infinity) {
            min = 0;
            max = 100;
        }

        range_min.value = min;
        range_max.value = max;

        console.log("min", min);
        console.log("max", max);

        // if we have a range set
        if (props.filter.field.storage.min != null && props.filter.field.storage.max != null) {
            props.filter.field.setTempValue({ min: props.filter.field.storage.min, max: props.filter.field.storage.max });

            props.filter.field.input_from
        }
        // we don't have a range set
        else {
            props.filter.field.setTempValue({ min: min, max: max });
        }

        loading.value = false;
    }

    onMounted(() => {
        update();
    });

    function apply() {
        if (isNaN(parseFloat(props.filter.field.temp.min)) || isNaN(parseFloat(props.filter.field.temp.max))) {
            alert($t("Invalid range for ") + props.filter.field.name, "danger", { autohide: true, delay: 3000 });
            hapticsImpact("Error");
            return;
        }
        props.filter.field.applyTempValue();
        props.filter.selectField(null);
        hapticsImpact("MediumImpact");
    }

    function clear() {
        props.filter.field.clear();
        props.filter.selectField(null);
        hapticsImpact("MediumImpact");
    }
</script>

<template>
    <m-container :show-spinner="loading">
        <template v-if="!loading">
            <m-content>
                <div class="mt-3 mx-4 d-flex justify-content-evenly">
                    <div style="width: 8rem;">
                        <div class="text-center">{{ $t("From") }}</div>
                        <input class="form-control text-center fw-medium" v-model="filter.field.temp.min" />
                    </div>
                    <div style="width: 8rem;">
                        <div class="text-center">{{ $t("To") }}</div>
                        <input class="form-control text-center fw-medium" v-model="filter.field.temp.max" />
                    </div>
                </div>
                <div class="mt-4 mx-5">
                    <MSlider
                        :min="range_min"
                        :max="range_max"
                        :start="filter.field.temp.min"
                        :stop="filter.field.temp.max"
                        @update:start="val => filter.field.temp.min = val"
                        @update:stop="val => filter.field.temp.max = val"
                    />
                </div>
            </m-content>
            <div class="p-3 d-flex flex-column gap-2">
                <button class="w-100 btn btn-outline-primary" @click="clear">
                    {{ $t("Clear") }}
                </button>
                <button class="w-100 btn btn-primary" @click="apply">
                    {{ $t("Apply") }}
                </button>
            </div>
        </template>
    </m-container>
</template>
